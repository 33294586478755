.gameBoard {
    color: white;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
}

.numPlayers {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px;
    text-align: center;
}