.red-flags-game{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 180px) 
}

.player-profile-row {
    display: flex;
    flex-direction: row;
}

.player-profile {
    margin: 10px;
    padding: 15px;
    border: 2px solid white;
    border-radius: 5px;
    width: 150px;
    min-height: 50px;

}

.player-info-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 2px;
    margin-bottom: 5px;
    border-bottom: 1px solid white;
}

.player-cards {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.pro {
    background-color: white;
    color: black;
    margin: 5px 0px;
    padding: 5px;
    border-radius: 3px;
}

.con {
    background-color: rgb(163, 19, 19);
    color: white;
    margin: 5px 0px;
    padding: 5px;
    border-radius: 3px;
}

.current-player {
    height: 150px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid white;
    overflow-x: auto;
}
.current-player-info-bar {
    display: flex;
    flex-direction: column;
    min-width: 100px;
    height: 100%;
    padding-left: 15px;
    border-right: 1px solid white;
}

.card-decks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    padding: 2px 20px;
    margin-bottom: 10px;
}

.card-deck {
    width: 80px;
    display: flex; 
    flex-direction: column; 
    align-items: center;
    font-size: 13px;
}

.select-button {
    display: block;
    position: absolute;
    padding: '1px';
    bottom: 5px;
    border-color: rgb(163, 19, 19);
    border-width: 1.5px;
}

.red-flag-decks {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.red-flags-game-container {
    position: relative;
    top: 0;
    left: 0;
    min-height: calc(100vh - 150px) 
}

.player-score {
    display: flex;
    flex-direction: row;
    min-height: 22px;
}