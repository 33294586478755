@import "./mixins.scss";
@import "./reset.scss";


body {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #232931 !important;
  background-repeat: no-repeat;
  background-size: 100%;
  //min-height: 100vh;
}

