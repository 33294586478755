.homepage {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.game-master {
    margin: 0px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    color: white !important;
}

.join-game {
    margin: 0px;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    color: white !important;
}

.join-game-form {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 250px;
}

.homepage .h1{
    font-size: 50px;
}

.gameTitle {
    font-size: 50px;
    color: white;
    padding: 20px 20px 40px
}

.gameCode {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #A9A9A9;    
    border-top: 1px solid #A9A9A9;
    padding: 20px 0px;
}

.gameError {
    font-size: x-large;
    margin: 80px auto;
}

.player-list {
    padding: 20px;
    width: 300px;
}

.player-title {
    width: 100%;
    text-align: center;
    font-size: x-large;
    margin-bottom: 10px;
}

.player-subtitle {
    width: 100%;
    text-align: center;
    font-size: small;
    margin-bottom: 10px;
}

ol {
    list-style: decimal;
}

.button-container {
    height: "100vh";
    width: "100%";
    padding: 0px;
    position: "absolute";
    top: 0;
    left: 0;
}

.bottom-button {
    position: "absolute";
    bottom: -10;
}